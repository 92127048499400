import styled from "styled-components";
import { useState, useEffect } from "react";
import { toast } from "sonner";
import ChannelSelect from "./ChannelSelect";
import ChannelImage from "./ChannelImage";
import { useMemo } from "react";

function ChannelQuickAccess(props) {
  const {
    selectedChannels,
    handleChannelSelect,
    mainChannel,
    allowOthers = true,
    checkOnlineStatus = true,
  } = props;

  // State
  const [streamerList, setStreamerList] = useState([]);
  const [quickAccessStreamers, setQuickAccessStreamers] = useState(
    // Retrieve the quickAccessStreamers from local storage, or use an empty array if none is found
    () => JSON.parse(localStorage.getItem("quickAccessStreamers")) || []
  );

  // Memo

  const quickAccessStreamersWithInfo = useMemo(() => {
    return streamerList.length > 0
      ? quickAccessStreamers.map((qas) => {
          const streamer = streamerList.find((s) => s.name === qas);
          return streamer;
        })
      : [];
  }, [quickAccessStreamers, streamerList]);

  // Fetch streamers
  const fetchStreamers = async (isInit) => {
    try {
      const response = await fetch("https://live.utopia-rp.fr/api/streamer");
      const data = await response.json();
      if (!data.success)
        return toast.error(
          "Une erreur est survenue lors de la récupération des streamers"
        );

      const streamers = data.streamers;
      setStreamerList(streamers);
      if (isInit) {
        // Add as quick access streamers the streamers that are already in the selectedChannels
        const preSelectedStreamers = selectedChannels.filter(
          (sc) =>
            streamers.some((s) => s.name === sc) &&
            !quickAccessStreamers.some((s) => s === sc)
        );
        const newQuickAccessStreamers = [
          ...preSelectedStreamers,
          ...quickAccessStreamers,
        ].filter((s) => streamers.some((d) => d.name === s));
        if (newQuickAccessStreamers.length > 15) {
          toast.message(
            "La limite de 15 streamers en quick access a été atteinte. Certains streamers ont été retirés."
          );
        }
        setQuickAccessStreamers(newQuickAccessStreamers.slice(0, 15));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch streamers on init
  useEffect(() => {
    fetchStreamers(true);
  }, []);

  // Refetch streamers every 5 minutes
  useEffect(() => {
    setInterval(() => {
      fetchStreamers(false);
    }, 300000);
  }, []);

  // Save quickAccessStreamers to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      "quickAccessStreamers",
      JSON.stringify(quickAccessStreamers)
    );
  }, [quickAccessStreamers]);

  return (
    <Container>
      {allowOthers && quickAccessStreamersWithInfo.length > 0 && (
        <>
          {quickAccessStreamersWithInfo.map((streamer) => (
            <ChannelImage
              streamer={streamer}
              key={streamer.name}
              handleChannelSelect={handleChannelSelect}
              isSelected={selectedChannels.includes(streamer.name)}
              isMainChannel={mainChannel === streamer.name}
              checkOnlineStatus={checkOnlineStatus}
              removeFromQuickAccess={() => {
                setQuickAccessStreamers(
                  quickAccessStreamers.filter((qas) => qas !== streamer.name)
                );
                if (selectedChannels.includes(streamer.name)) {
                  handleChannelSelect(streamer.name);
                }
              }}
              selectChannel={handleChannelSelect}
            />
          ))}
        </>
      )}
      {allowOthers && (
        <ChannelSelect
          streamers={streamerList.filter(
            (streamer) =>
              !streamer.isMadz &&
              quickAccessStreamers.indexOf(streamer.name) === -1
          )}
          addQuickAccessStreamer={(streamer) => {
            if (
              quickAccessStreamers.some(
                (quickAccessStreamer) => quickAccessStreamer === streamer
              )
            ) {
              toast.message(
                `${streamer} est déjà dans la liste (tu peux le retirer en cliquant sur le x à côté de son image))`
              );
              return;
            }
            if (quickAccessStreamers.length >= 15) {
              toast.error(
                `Tu ne peux pas ajouter plus de 15 streamers en quick access`
              );
              return;
            }
            setQuickAccessStreamers([...quickAccessStreamers, streamer]);
          }}
        ></ChannelSelect>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default ChannelQuickAccess;
