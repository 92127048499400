import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

function ChannelSelect(props) {
  const { streamers, addQuickAccessStreamer } = props;
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsSelecting(false);
      }

      return true;
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsSelecting(false);
    }
  }, []);
  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    const results = streamers.filter(
      (s) =>
        s.display_name.toLowerCase().includes(search.toLowerCase()) ||
        s.title.toLowerCase().includes(search.toLowerCase())
    );
    setSearchResults(results);
  }, [search, streamers]);

  return (
    <Container ref={ref}>
      <AddStreamer
        onClick={(e) => {
          setIsSelecting(!isSelecting);
        }}
      >
        {isSelecting ? "x" : "+"}
      </AddStreamer>

      {isSelecting && (
        <SearchContainer>
          <SearchBar
            type="text"
            placeholder="Rechercher un streamer en ligne (nom ou titre du stream)"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchResults>
            {searchResults.map((streamer) => (
              <SearchResult
                key={streamer.id}
                onClick={() => {
                  addQuickAccessStreamer(streamer.name);
                }}
              >
                <SearchResultImage src={streamer.profile_image_url} />
                <SearchResultInfo>
                  <SearchResultName>{streamer.display_name}</SearchResultName>
                  <SearchResultStreamTitle>
                    {streamer.title}
                  </SearchResultStreamTitle>
                </SearchResultInfo>
              </SearchResult>
            ))}
          </SearchResults>
        </SearchContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const AddStreamer = styled.div`
  position: relative;
  margin: 0 8px;
  align-items: center;
  justify-content: center;
  border-radius: 25%;
  font-weight: bold;
  cursor: pointer;
  border: "2px solid transparent";
  transition: all 0.3s ease-in-out;
  width: 40px;
  height: 40px;
  background-color: grey;
  display: flex;
  opacity: 0.3;
  // hover
  &:hover {
    border: "2px solid grey";
    opacity: 1;
  }
  // Remove text selection
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  top: 60px;
  right: 8px;
  width: 400px;
  min-height: 300px;
  max-height: 600px;
  z-index: 100;
  border-radius: 10px;
  background-color: #20232a;
`;

const SearchBar = styled.input`
  border: 1px solid #e6e6e6;
  font-size: 16px;
  background-color: #20232a;
  border: none;
  border-radius: 10px 10px 0 0;
  padding: 8px;
  color: #e6e6e6;
  border-bottom: 1px solid #282c34;
  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    padding: 0 10px;
    font-size: 14px;
  }
  :-moz-placeholder {
    padding: 0 10px;
    font-size: 14px;
  }
`;

const SearchResultImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 25%;
  margin-right: 10px;
`;

const SearchResultName = styled.div`
  font-size: 16px;
`;
const SearchResultStreamTitle = styled.div`
  font-size: 0.7rem;
  color: #a6a6a6;
`;

const SearchResultInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const SearchResults = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // Remove text selection
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const SearchResult = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: #282c34;
  }
`;

export default ChannelSelect;
