import { TwitchChat } from "react-twitch-embed";
import styled from "styled-components";
import { memo, useEffect, useState } from "react";
import PlayerGrid from "../components/MultiPov/PlayerGrid";
import { toast } from "sonner";
import ChannelQuickAccess from "../components/MultiPov/ChannelQuickAccess";
import { Allotment } from "allotment";
// import PatchNoteToast from "../components/PatchNoteToast";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import EmptyState from "../components/MultiPov/EmptyState";

// Memoized TwitchEmbed component
const MemoizedTwitchChat = memo((props) => (
  <TwitchChat {...props} theme="dark" height="100%" width="100%" title="Test" />
));

function MultiPovPage() {
  const [mainChannel, setMainChannel] = useState(undefined);
  const [selectedChannels, setSelectedChannels] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  let [initFromUrl, setInitFromUrl] = useState(false);
  const [showWelcomeToast, setShowWelcomeToast] = useState(false);

  // Toast a tutorial message at first render to explain how to use the app
  useEffect(() => {
    if (showWelcomeToast) return;
    // toast.custom((a) => <PatchNoteToast toastRef={a} />, {
    //   duration: 10000,
    // });
    setShowWelcomeToast(true);
  }, [showWelcomeToast, setShowWelcomeToast]);

  const handleChannelSelect = (channelName) => {
    if (selectedChannels.includes(channelName)) {
      setSelectedChannels(selectedChannels.filter((c) => c !== channelName));
      if (mainChannel === channelName) {
        setMainChannel(
          selectedChannels.length > 1
            ? selectedChannels.find((s) => s !== mainChannel)
            : undefined
        );
      }
    } else {
      if (selectedChannels.length >= 5) {
        toast.error("Il n'est pas possible de regarder plus de 5 streams");
        return;
      }
      setSelectedChannels([...selectedChannels, channelName]);
      if (mainChannel === undefined) {
        setMainChannel(channelName);
      }
    }
  };

  useEffect(() => {
    if (selectedChannels.length === 0) {
      setSearchParams({});
      return;
    }
    setSearchParams({ selectedChannels: selectedChannels.join(",") });
  }, [setSearchParams, selectedChannels]);

  useEffect(() => {
    const selectedChannels = searchParams.get("selectedChannels");
    setInitFromUrl(true);
    if (!selectedChannels) return;
    const channels = searchParams.get("selectedChannels").split(",") || [];
    setSelectedChannels(channels);
    setMainChannel(channels[0] || undefined);
  }, [searchParams]);

  return (
    <div>
      <Header>
        {initFromUrl && (
          <ChannelQuickAccess
            selectedChannels={selectedChannels}
            handleChannelSelect={handleChannelSelect}
            mainChannel={mainChannel}
          />
        )}
      </Header>
      <Container>
        {mainChannel ? (
          <Allotment>
            <Allotment.Pane>
              <PlayerGrid
                selectedChannels={selectedChannels}
                setMainChannel={setMainChannel}
                mainChannel={mainChannel}
              />
            </Allotment.Pane>
            <Allotment.Pane
              maxSize={500}
              preferredSize={400}
              minSize={300}
              snap
            >
              <MemoizedTwitchChat channel={mainChannel} />
            </Allotment.Pane>
          </Allotment>
        ) : (
          <>
            <EmptyState />
          </>
        )}
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  height: calc(100vh - 60px);
  width: 100%;
`;

export default MultiPovPage;
