import styled from "styled-components";
import { toast } from "sonner";
import { useState } from "react";

function ChannelImage(props) {
  const {
    streamer,
    isSelected,
    isMainChannel,
    handleChannelSelect,
    removeFromQuickAccess,
    checkOnlineStatus = true,
  } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <TwitchChannelImageContainer
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <TwitchChannelImage
        key={streamer.name}
        src={streamer.profile_image_url}
        alt={`${streamer.name} avatar`}
        isSelected={isSelected}
        isMain={isMainChannel}
        isOnline={streamer.online}
        isStreamingUtopia={true}
        onClick={() => {
          if (streamer.online || !checkOnlineStatus || isSelected) {
            handleChannelSelect(streamer.name);
          } else {
            toast.error(`${streamer.name} est hors ligne`, {
              action: {
                label: "Voir la chaîne",
                onClick: () =>
                  window.open(`https://www.twitch.tv/${streamer.name}`),
              },
            });
          }
        }}
      />
      {checkOnlineStatus && (
        <TwitchChannelStatus
          isOnline={streamer.online}
          isStreamingUtopia={true}
        />
      )}
      {checkOnlineStatus && isHover && (
        <HoverInfoContainer>
          <StreamerInfo>
            <StreamerName>{streamer.display_name}</StreamerName>
            {streamer.online && (
              <>
                <StreamerStreamTitle>
                  {streamer.streamTitle}
                </StreamerStreamTitle>
                <StreamerThumbnail src={streamer.thumbnail_url} />{" "}
              </>
            )}
          </StreamerInfo>
        </HoverInfoContainer>
      )}
      {isHover && !streamer.isMadz && (
        <RemoveButton onClick={() => removeFromQuickAccess()}>x</RemoveButton>
      )}
    </TwitchChannelImageContainer>
  );
}

const RemoveButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  // Remove text selection
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const TwitchChannelImageContainer = styled.div`
  position: relative;
`;

const TwitchChannelStatus = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: grey;
  border: 2px solid #282c34;
  bottom: 0;
  right: 4px;
  position: absolute;
  background-color: ${({ isOnline, isStreamingUtopia }) =>
    isOnline ? (isStreamingUtopia ? "green" : "orange") : "grey"};
`;

const TwitchChannelImage = styled.img`
  margin: 0 8px;
  border-radius: 25%;
  cursor: ${({ isOnline, isSelected, checkOnlineStatus }) =>
    !checkOnlineStatus || isOnline || isSelected ? "pointer" : "default"};

  transition: all 0.3s ease-in-out;
  width: 40px;
  height: 40px;

  position: relative;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.2)};
  border: 2px solid transparent;

  // on hover and online, show red border
  &:hover {
    border: ${({ isOnline, isSelected }) =>
      isOnline && isSelected ? "2px solid red" : "2px solid transparent"};
    opacity: 1;
  }
`;

const HoverInfoContainer = styled.div`
  position: absolute;
  top: 50px;
  z-index: 100;
  right: 0;
  background-color: #20232a;
  color: white;
  padding: 8px;
  border-radius: 8px;
`;

const StreamerName = styled.div`
  font-size: 16px;
`;
const StreamerStreamTitle = styled.div`
  font-size: 0.7rem;
  color: #a6a6a6;
`;

const StreamerThumbnail = styled.img`
  width: 320px;
  height: 180px;
  border-radius: 8px;
  margin-top: 8px;
`;

const StreamerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export default ChannelImage;
