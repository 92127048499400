import styled from "styled-components";
import EmptyStateImg from "../../assets/empty-state.jpg";
import MadzLogo from "../../assets/logo.png";

function EmptyState() {
  return (
    <Container>
      <LeftPaneImage backgroundUrl={EmptyStateImg} />
      <img src={MadzLogo} alt="Madz Logo" style={{ height: "120px" }} />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 3rem;
    margin: 0;
    padding: 0;
    color: white;
  }
`;

const LeftPaneImage = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.3;
  position: absolute;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  z-index: -1;
`;

export default EmptyState;
