import styled from "styled-components";
import Header from "../components/Header";
import MadzText from "../components/MadzText";
import { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../components/Loader";

function VodsPage() {
  // State
  const [vodsList, setVodsList] = useState([]);

  // Fetch vods
  const fetchVods = async () => {
    try {
      const response = await fetch(
        "https://madz-multi-pov-back.vercel.app/listMadzVods"
      );
      const data = await response.json();
      setVodsList(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch streamers on init
  useEffect(() => {
    fetchVods();
  }, []);

  // Sort vods based on selected order
  const sortedVods = vodsList.slice().sort((a, b) => {
    return moment(b.creationDate).diff(a.creationDate);
  });

  return (
    <div>
      <Header></Header>
      <Container>
        <SectionTitle>VODs des 30 derniers jours</SectionTitle>
        {vodsList.length === 0 ? (
          <Loader />
        ) : (
          <>
            <VodList>
              {sortedVods.map((vod) => (
                <VodContainer
                  key={vod.vodId}
                  href={vod.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <VodThumbnail backgroundUrl={vod.thumbnailUrl}>
                    <VodViewCount>{vod.views} vues</VodViewCount>
                    <VodDuration>{vod.duration}s</VodDuration>
                    <VodDate>{moment(vod.creationDate).fromNow()}</VodDate>
                  </VodThumbnail>
                  <VodInformation>
                    <TwitchChannelImage src={vod.channelThumbnailUrl} />
                    {vod.title}
                  </VodInformation>
                </VodContainer>
              ))}
            </VodList>
          </>
        )}
      </Container>
    </div>
  );
}

const SectionTitle = styled(MadzText)`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  min-height: calc(100vh - 60px);
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-sizing: border-box;
`;

const VodList = styled.div`
  display: grid;
  // Exacly 320px width for each vod
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  margin-top: 10px;
`;

const VodContainer = styled.a`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
`;

const VodThumbnail = styled.div`
  width: 320px;
  height: 180px;
  border-radius: 5px;
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
`;

const TwitchChannelImage = styled.img`
  margin-right: 5px;
  border-radius: 25%;

  width: 30px;
  height: 30px;
`;

const VodInformation = styled.div`
  display: flex;
  flex-direction: row;
  width: 320px;
  margin-top: 5px;
`;

const VodViewCount = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;

const VodDuration = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;

const VodDate = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;

export default VodsPage;
