import styled from "styled-components";
import Header from "../components/Header";
import MadzText from "../components/MadzText";
import { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../components/Loader";

function ClipsPage() {
  // State
  const [clipsList, setClipsList] = useState([]);
  const [orderBy, setOrderBy] = useState("date");

  // Fetch clips
  const fetchClips = async () => {
    try {
      const response = await fetch(
        "https://madz-multi-pov-back.vercel.app/listMadzClips"
      );
      const data = await response.json();
      setClipsList(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch streamers on init
  useEffect(() => {
    fetchClips();
  }, []);

  // Handle order change
  const handleOrderChange = (event) => {
    setOrderBy(event.target.value);
  };

  // Sort clips based on selected order
  const sortedClips = clipsList.slice().sort((a, b) => {
    if (orderBy === "views") {
      return b.views - a.views;
    } else {
      return moment(b.creationDate).diff(a.creationDate);
    }
  });

  return (
    <div>
      <Header></Header>
      <Container>
        <SectionTitle>Clips des 7 derniers jours</SectionTitle>
        {clipsList.length === 0 ? (
          <Loader />
        ) : (
          <>
            <OrderContainer>
              <OrderLabel>Trié par: </OrderLabel>
              <OrderSelect value={orderBy} onChange={handleOrderChange}>
                <option value="date">Plus récent</option>
                <option value="views">Plus de vues</option>
              </OrderSelect>
            </OrderContainer>
            <ClipList>
              {sortedClips.map((clip) => (
                <ClipContainer
                  key={clip.clipId}
                  href={clip.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ClipThumbnail backgroundUrl={clip.thumbnailUrl}>
                    <ClipViewCount>{clip.views} vues</ClipViewCount>
                    <ClipDuration>{clip.duration}s</ClipDuration>
                    <ClipDate>{moment(clip.creationDate).fromNow()}</ClipDate>
                  </ClipThumbnail>
                  <ClipInformation>
                    <TwitchChannelImage src={clip.channelThumbnailUrl} />
                    {clip.title}
                  </ClipInformation>
                </ClipContainer>
              ))}
            </ClipList>
          </>
        )}
      </Container>
    </div>
  );
}

const SectionTitle = styled(MadzText)`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;

const ClipList = styled.div`
  display: grid;
  // Exacly 320px width for each clip
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
`;

const ClipContainer = styled.a`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
`;

const ClipThumbnail = styled.div`
  width: 320px;
  height: 180px;
  border-radius: 5px;
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: center;
`;

const TwitchChannelImage = styled.img`
  margin-right: 5px;
  border-radius: 25%;

  width: 30px;
  height: 30px;
`;

const ClipInformation = styled.div`
  display: flex;
  flex-direction: row;
  width: 320px;
  margin-top: 5px;
`;

const ClipViewCount = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;

const ClipDuration = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;

const ClipDate = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #20232a;
  color: white;
  padding: 2px;
  border-radius: 5px;
  font-size: 0.7rem;
`;

const OrderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OrderLabel = styled.span`
  margin-right: 10px;
`;

const OrderSelect = styled.select`
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
`;

export default ClipsPage;
