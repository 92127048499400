import { useRouteError } from "react-router-dom";
import Header from "../components/Header";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <>
      <Header />
      <div
        id="error-page"
        style={{
          margin: "20px",
        }}
      >
        <h2>Oops!</h2>
        <p>Désolé, une erreur est survenue.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <p>
          N'hésite pas à screenshot cette page et à la partager sur le discord
          de JunpeiFrance sur le channel #retours-madz-multi-pov
        </p>
      </div>
    </>
  );
}
