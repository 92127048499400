import React, { memo, useCallback, useEffect, useRef } from "react";
import { TwitchEmbed } from "react-twitch-embed";
import styled from "styled-components";
import "allotment/dist/style.css";

// Memoized TwitchEmbed component
const MemoizedTwitchEmbed = memo((props) => {
  return (
    <TwitchEmbed
      id={props.id}
      channel={props.channel}
      height={"100%"}
      width={"100%"}
      withChat={false}
      darkMode={false}
      muted={true}
      onVideoReady={props.updateTwitchEmbeds}
    />
  );
});

const PlayerGrid = React.memo((props) => {
  const { selectedChannels, mainChannel, setMainChannel } = props;
  const twitchEmbeds = useRef([]);
  const playerRefs = useRef([]);
  const gridRef = useRef();

  useEffect(() => {
    updateGridTemplateRows();
    checkChannelPositions();
    checkChannelsVolume();
  }, [selectedChannels, mainChannel]);

  const handleMainChannelChange = useCallback((channelName) => {
    setMainChannel(channelName);
    checkChannelPositions(channelName);
    checkChannelsVolume(channelName);
  });

  const checkChannelPositions = useCallback((mainChannelOverride) => {
    for (var i = 0; i < playerRefs.current.length; i++) {
      var playerRef = playerRefs.current[i];
      var channelName = playerRef.channelName;
      if (playerRef.ref === undefined || playerRef.ref === null) continue;
      if (
        playerRef.channelName === mainChannel ||
        channelName === mainChannelOverride
      ) {
        playerRef.ref.style.gridColumn = "1";
        playerRef.ref.style.gridRow = "1/-1";
      } else {
        playerRef.ref.style.gridColumn = "initial";
        playerRef.ref.style.gridRow = "initial";
      }
    }
  });

  const updateGridTemplateRows = useCallback(() => {
    if (selectedChannels.length === 1)
      gridRef.current.style.gridTemplateColumns = `1fr`;
    else gridRef.current.style.gridTemplateColumns = `80% 20%`;
    if (gridRef.current === undefined || gridRef.current === null) return;
    gridRef.current.style.gridTemplateRows = `repeat(${
      selectedChannels.length - 1
    }, 1fr)`;
  });

  const checkChannelsVolume = useCallback((mainChannelOverride) => {
    for (var i = 0; i < twitchEmbeds.current.length; i++) {
      var channelName = twitchEmbeds.current[i].getChannel();
      if (
        (mainChannelOverride === undefined && channelName === mainChannel) ||
        channelName === mainChannelOverride
      ) {
        twitchEmbeds.current[i].setMuted(false);
      } else {
        twitchEmbeds.current[i].setMuted(true);
      }
    }
  });

  const updateTwitchEmbeds = useCallback((embed) => {
    if (twitchEmbeds.current.some((e) => e.getChannel() === embed.getChannel()))
      twitchEmbeds.current = twitchEmbeds.current.filter(
        (e) => e.getChannel() !== embed.getChannel()
      );
    twitchEmbeds.current = [...twitchEmbeds.current, embed];
  }, []);

  return (
    <GridContainer ref={gridRef}>
      {selectedChannels.map((channelName) => (
        <ClickablePlayer
          key={channelName}
          ref={(ref) => {
            if (playerRefs.current.some((e) => e.channelName === channelName))
              playerRefs.current = playerRefs.current.filter(
                (e) => e.channelName !== channelName
              );
            playerRefs.current = [
              ...playerRefs.current,
              {
                channelName,
                ref,
              },
            ];
          }}
        >
          <MemoizedTwitchEmbed
            id={channelName}
            channel={channelName}
            updateTwitchEmbeds={updateTwitchEmbeds}
          />
          {channelName !== mainChannel && (
            <Overlay onClick={() => handleMainChannelChange(channelName)}>
              <OverlayHeader>{channelName}</OverlayHeader>
            </Overlay>
          )}
        </ClickablePlayer>
      ))}
    </GridContainer>
  );
});

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  height: 100%;
`;

const ClickablePlayer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  pointer-events: auto;
  &:hover {
    opacity: 0.8;
  }
`;

const OverlayHeader = styled.div`
  background-color: #282c34;
  text-align: center;
  position: relative;
`;

export default PlayerGrid;
